<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}监控</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确定</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="监控名称">
          <a-input v-model:value.trim="monitorName" placeholder="请输入标题" allowClear></a-input>
        </a-form-item>
        <a-form-item label="播流地址">
          <a-input v-model:value.trim="liveAddress" placeholder="请输入播流地址" allowClear></a-input>
        </a-form-item>
        <a-form-item label="位置经度">
          <a-input v-model:value.trim="longitude" placeholder="请输入经度" allowClear></a-input>
        </a-form-item>
        <a-form-item label="位置纬度">
          <a-input v-model:value.trim="latitude" placeholder="请输入纬度" allowClear></a-input>
        </a-form-item>
        <a-form-item label="资源">
          <a-tree-select
              show-search
              v-model:value="resourceId"
              placeholder="请选择资源"
              allow-clear
              :tree-data="ResourceSelectionList"
              :replaceFields="replaceFields"
              dropdownClassName="live-configuration-treeSelect"
            >
            </a-tree-select>
        </a-form-item>
      </a-form>
      <div class="setpoint-map-box">
        <div class="bm-view">
          <gaode-map @ready="mapInit" @set-point="handleSetPoint"></gaode-map>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import api from '@/services';
import { message } from 'ant-design-vue';
// import coordtransform from 'coordtransform';
import GaodeMap from '@/components/gaode-map';
const replaceFields = {
  children: 'children',
  title: 'resourceName',
  value: 'key',
  key: 'key'
};
export default defineComponent({
  components: {
    GaodeMap
  },
  emits: ['addOrUpdateSuccess'],
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      monitorName: '',
      liveAddress: '',
      longitude: '',
      latitude: '',
      resourceId: ''
    });

    onMounted(() => {});
    
    const mapState = reactive({
      map: null,
      marker: null
    });

    const handleSetPoint = e => {
      formState.longitude = e.lnglat.getLng();
      formState.latitude = e.lnglat.getLat();

      mapState.marker && mapState.map.remove(mapState.marker);
      mapState.marker = new AMap.Marker({
        position: new AMap.LngLat(formState.longitude, formState.latitude)
      });
      // 将创建的点标记添加到已有的地图实例：
      mapState.map.add(mapState.marker);
    };

    const mapInit = ({ map }) => {
      mapState.map = map;
    };

    const showModal = (flag, rowData) => {
      visible.value = true;
      getaddMonitorTree()
      nextTick(() => {
        isEdit.value = flag;
        if (flag) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
        } 
      });
    };

    const handleOk = async () => {
      loading.value = true;
      Object.keys(formState).map(key => {
        if(!formState[key]) {
          loading.value = false
        }
      });
      if(!loading.value) {
        message.error('请输入完整')
        return false 
      }
      const functionName = isEdit.value ? 'updateMonitor' : 'addMonitor';

      let params = {
        ...formState
      };

      const { success } = await api[functionName](params);
      if (success) {
        message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
        clearForm();
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    const ResourceSelectionList = ref([]);
    const getaddMonitorTree = async () => {
      let { data } = await api.addMonitorTree();
      ResourceSelectionList.value = data;
    };

    return {
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm,
      mapCenter: {
        lng: 120.633303,
        lat: 31.316615
      },
      markerPoint: {},
      mapZoom: 15,
      handleSetPoint,

      ResourceSelectionList,
      replaceFields,
      mapInit
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
  .bm-view {
    width: 100%;
    height: 300px;
  }
}
</style>
