<template>
  <div class="wrap">
    <p class="title">监控管理</p>

    <!-- 操作 -->
    <div class="action">
      <!-- <a-input-search v-model:value="monitorName" style="width: 300px" placeholder="搜索监控名称" @search="searchName">
        <template #enterButton>
          <a-button>搜索</a-button>
        </template>
      </a-input-search> -->
      <a-button type="primary" size="small" @click="showAddEditModal">新增监控</a-button>
    </div>
    <div class="main-div">
      <a-menu mode="inline" class="menu-left" >
        <template v-for="item in monitorList" :key="item.key">
          <template v-if="!item.children">
              <a-menu-item :key="item.key"  >
                <span >{{ item.resourceName }}123</span>
              </a-menu-item>
            </template>
            <template v-else>
            <sub-menu :menu-info="item" :key="item.key" />
          </template>
        </template>
      </a-menu>
      <!-- 表格 -->
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="{ pageSize: pageSize, current: current, total: total }"
        :row-key="record => record.id"
        @change="handlePageChange"
      >
        <template #idx="row">
          {{ (current - 1) * pageSize + row.index + 1 }}
        </template>
        <template #status="row">
          {{ row.record.status ? '停止中' : '运行中' }}
        </template>
        <template #action="row">
          <div class="action">
            <span class="edit" @click="toggleMonitorStatus(row)">{{ row.record.status ? '启动' : '停止' }}</span>
            <span class="edit" @click="showAddEditModal(row)">编辑</span>
            <span class="del" @click="del(row)">删除</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <add-edit-monitor-modal ref="AddEditMonitorRef" @addOrUpdateSuccess="search"></add-edit-monitor-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditMonitorModal from './AddEditMonitorModal';
import SubMenu from './SubMenu';
import bus from '../../../../bus';
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx',
    },
  },
  {
    title: '监控名称',
    dataIndex: 'monitorName',
    align: 'center',
    ellipsis: true,
  },
  {
    title: '播流地址',
    dataIndex: 'liveAddress',
    align: 'center',
  },
  {
    title: '新增时间',
    dataIndex: 'createTime',
    align: 'center',
  },
  {
    title: '位置',
    dataIndex: 'location',
    align: 'center',
  },
  {
    title: '运行状态',
    dataIndex: 'status',
    align: 'center',
    slots: {
      customRender: 'status',
    },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 250,
    align: 'center',
    slots: {
      customRender: 'action',
    },
  },
];
export default defineComponent({
  components: {
    AddEditMonitorModal,
    'sub-menu': SubMenu
  },
  setup() {
    onMounted(() => {
      getMonitorList();
      monitoringTree();
    });

    const AddEditMonitorRef = ref();
    const isEdit = ref(false);

    const visible = ref(false);

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      current: 1,
      total: 0,
      dataSource: [],
      columns,
    });

    // 获取监控列表 table data
    const key = ref('')
    const monitorName = ref('');
    const getMonitorList = async () => {
      console.log('getMonitorList')
      const params = {
        curPage: tableState.current,
        pageSize: tableState.pageSize,
        monitorName: monitorName.value,
        key: key.value || 'res320508',
      };
      delete params.rangeDate;

      const { data, success } = await api.getMonitorList(params);
      console.log(data, success)
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    const monitorList = ref([]) 
    const monitoringTree = async () => {
      const { data, success } = await api.monitoringTree();
      monitorList.value = data
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditMonitorRef.value.showModal(isEdit.value, rowData);
    };

    const toggleMonitorStatus = async row => {
      console.log('id===', row.record.id);
      let params = {
        monitorId: row.record.id,
      };
      const { success } = await api.toggleMonitorStatus(params);
      if (success) {
        message.success('切换成功！');
        getMonitorList();
      }
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;',
          },
          `确认要删除该监控吗？`
        ),

        async onOk() {
          let { success } = await api.delMonitorById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          }
        },
      });
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const handlePageChange = page => {
      tableState.current = page.current;
      getMonitorList();
    };

    const search = () => {
      tableState.current = 1;
      getMonitorList();
    };

    // const searchName = () => {
    //   getMonitorList();
    // };
    bus.on('searchFrom', (params) => {
      key.value = params.key
      tableState.current = 1;
      getMonitorList();
    });

    return {
      AddEditMonitorRef,
      showAddEditModal,
      isEdit,
      visible,
      getMonitorList,
      handlePageChange,
      toggleMonitorStatus,
      search,
      del,
      handleCancel,
      ...toRefs(tableState),
      monitorName,
      // searchName,
      monitorList,
    };
  },
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .main-div {
    display: flex;
    .menu-left {
      flex: 1;
      margin-right: 10px;
      margin-top: 15px;
      max-height: 770px;
      overflow-y: auto
    }
  }
  .action {
    display: flex;
    justify-content: space-between;
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    flex: 4;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
