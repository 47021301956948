<!--
 * @Author: your name
 * @Date: 2022-01-04 11:21:10
 * @LastEditTime: 2022-01-04 12:13:52
 * @LastEditors: Please set LastEditors
 * @Description: 高德地图组件
 * @FilePath: /smart-space/src/components/gaode-map/index.vue
-->
<template>
  <div class="app-map" id="appMap"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { nextTick } from 'vue';
export default {
  setup(props, context) {
    AMapLoader.load({
      key: 'b41990b616f8989146ae5944fadcad58', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      AMapUI: {
        // 是否加载 AMapUI，缺省不加载
        version: '1.1', // AMapUI 缺省 1.1
        plugins: [] // 需要加载的 AMapUI ui插件
      },
      Loca: {
        // 是否加载 Loca， 缺省不加载
        version: '1.3.2' // Loca 版本，缺省 1.3.2
      }
    })
      .then(AMap => {
        nextTick(() => initMap(AMap));
      })
      .catch(e => {
        console.log(e);
      });

    const initMap = AMap => {
      const map = new AMap.Map('appMap', {
        center: [120.63495664842202, 31.396590027410642], //中心点坐标
        zoom: 11 //级别
        // zooms: [10, 16] //设置地图级别范围
      });
      // state.map.setZoomAndCenter(zoom, [lng, lat]); //同时设置地图层级与中心点
      // map.setMapStyle('amap://styles/grey'); // 设置地图皮肤

      map.on('complete', () => {
        context.emit('ready', { map });

        map.on('click', e => {
          context.emit('set-point', e);
        });
      });
    };
  }
};
</script>

<style lang="less" scoped>
.app-map {
  width: 100%;
  height: 100%;

  ::v-deep(.amap-logo),
  ::v-deep(.amap-copyright) {
    display: none !important;
  }
}
</style>
