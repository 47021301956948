
<template>
  <a-sub-menu v-bind="$attrs">
    <template #title>
      <span>
        <span @click="searchFrom(menuInfo)">{{ menuInfo.resourceName }}</span>
      </span>
    </template>
    <template v-for="item in menuInfo.children" :key="item.key">
      <template v-if="!item.children">
        <a-menu-item :key="item.key" @click="searchFrom(item)">
          <span >{{ item.resourceName }}</span>
        </a-menu-item>
      </template>
      <template v-else>
        <sub-menu :menu-info="item" :key="item.key" />
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
import bus from '../../../../bus';
export default {
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, content) { 
    const searchFrom = (params) => {
      console.log(params);
      bus.emit('searchFrom', params);
    };
    return { searchFrom };
  },
};
</script>
